html, body {
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.grad, .solid {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: -1;
}

.solid {
  background: rgba(165,20,240,1);
}

.grad.-x {
  background: linear-gradient(to right, rgba(255,70,45,0) 0%, rgba(255,70,45,1) 100%);
}

.grad.-y {
  background: linear-gradient(to bottom, rgba(255,70,45,0) 0%, rgba(255,70,45,1) 100%);
}

.grad.-z {
  background: linear-gradient(45deg, rgba(255,195,35,1) 0%, rgba(255,195,35,0) 50%);
}

svg {
  stroke: white;
  stroke-linejoin: square;
  stroke-linecap: square;
  stroke-dasharray: 2000;
  stroke-width: 0;
  fill: none;
  -webkit-transform: scale(1);
          transform: scale(1);
}

svg:hover {
  -webkit-animation: pop .1s ease forwards;
          animation: pop .1s ease forwards;
  cursor: pointer;
}

svg.-animate path.c1 {
  -webkit-animation: drawc1 2s ease forwards;
          animation: drawc1 2s ease forwards;
}

svg.-animate path.y1 {
  -webkit-animation: drawy1 2s ease forwards;
          animation: drawy1 2s ease forwards;
}

svg.-animate path.y2 {
  -webkit-animation: drawy2 2s ease forwards;
          animation: drawy2 2s ease forwards;
}

@-webkit-keyframes pop {
  0% { -webkit-transform: scale(1); transform: scale(1) }
  80% { -webkit-transform: scale(1.1); transform: scale(1.1) }
  100% { -webkit-transform: scale(1.07); transform: scale(1.07) }
}

@keyframes pop {
  0% { -webkit-transform: scale(1); transform: scale(1) }
  80% { -webkit-transform: scale(1.1); transform: scale(1.1) }
  100% { -webkit-transform: scale(1.07); transform: scale(1.07) }
}

@-webkit-keyframes drawc1 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 1999;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 1999;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1200;
  }
}

@keyframes drawc1 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 1999;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 1999;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1200;
  }
}

@-webkit-keyframes drawy1 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 1999;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 1999;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1400;
  }
}

@keyframes drawy1 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 1999;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 1999;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1400;
  }
}

@-webkit-keyframes drawy2 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 2300;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 2300;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1600;
  }
}

@keyframes drawy2 {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 2300;
  }

  10% {
    stroke-width: 25;
    stroke-dashoffset: 2300;
  }

  100% {
    stroke-width: 25;
    stroke-dashoffset: 1600;
  }
}
